<template>
    <div style="min-height: 100vh;background-color: #F8F9FA;">
        <van-skeleton :row="5" v-if="loading" />
        <div v-else>
            <!-- 商品信息 -->
            <div class="good">
                <div class="goodTop">
                    <img src="../../../images/order/poster.png" alt="" class="goodPic">
                    <div class="goodInfo">
                        <div style="display:flex;flex-direction: column;">
                            <span>{{ commodityName }}</span>
                            <span class="originalPrice">原价 ￥{{ versionList[versionIndex].originalPrice }}</span>
                        </div>
                        <span class="price">￥{{ versionList[versionIndex].discountedPrice }}</span>
                    </div>
                </div>

                <!-- 商品版本 -->
                <div class="goodVersion">
                    <div class="wrapperTitle" style="justify-content: space-between;" @click="expandVersion">
                        <div class="display_flex">
                            <div class="line"></div>
                            选择版本
                        </div>
                        <div v-if="versionList.length > 4" :class="versionExpand ? 'scroll' : 'noscroll'">
                            <van-icon name="arrow" size="14px" color="#999999" />
                        </div>
                    </div>
                    <div class="versionBox">
                        <div v-for="(item, index) in versionList" :key="index">
                            <div v-if="!versionExpand">
                                <div v-if="index < 3" :class="['version', index == versionIndex ? 'versionActive' : '']"
                                    @click="chooseVersion(index)">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div v-else>
                                <div :class="['version', index == versionIndex ? 'versionActive' : '']"
                                    @click="chooseVersion(index)">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 不同版本的图片 -->
                <div class="goodImg">
                    <img :src="osspath + versionList[versionIndex].image" alt="" style="width:100%;height: 100%;">
                </div>
            </div>

            <div class="health" @click="healthSurvey">健康调查问卷
                <div class="healthButton">去填写</div>
            </div>

            <!-- 换购商品 -->
            <div class="additional" v-if="additionalShow">
                <div class="additionalInfo">
                    <div class="display_flex" style="width:90%">
                        <img :src="osspath + pattern.image" alt="" class="additionalPic">
                        <div class="additionalContent">
                            <div class="wordBreak" style="font-size: 14px;color: #333333;">
                                {{ additionalName }}
                            </div>
                            <div class="display_flex">
                                <span style="color:#E02020 ;">￥{{ pattern.discountedPrice }}</span>
                                <span
                                    style="font-size: 12px;font-weight: 400;color: #999999;margin-left: 8px;text-decoration: line-through;">￥{{
                                        pattern.originalPrice }}</span>
                            </div>
                        </div>
                    </div>
                    <van-checkbox v-model="additionalChecked" icon-size="16px" @click="showLocation"></van-checkbox>
                </div>
                <div class="choosePattern">
                    <span style="font-size: 14px;color: #333333;">款式选择</span>
                    <span :style="{ fontSize: '14px', fontweight: ' 400', color: pattern != '' ? '#333333' : '#999999' }"
                        @click="choosePattern">{{ pattern != '' ?
                            pattern.name : '请选择' }}<van-icon name="arrow" size="14px" color="#999999" /></span>
                </div>
            </div>

            <!-- 收货信息 -->
            <div class="mailLocation" v-if="additionalChecked">
                <div class="wrapperTitle" style="padding:0 0 8px 0;">
                    <div class="line"></div>
                    收货信息：
                </div>
                <div class="inputBox">
                    <div class="inputTitle">收货人<span class="star">*</span></div>
                    <van-field v-model.trim="receivingInfo.userName" maxlength="20" input-align="right" placeholder="请填写" />
                    <div style="width:14px"></div>
                </div>
                <div class="inputBox">
                    <div class="inputTitle"> 联系电话<span class="star">*</span></div>
                    <van-field v-model="receivingInfo.phone" maxlength="11" input-align="right" type="number"
                        placeholder="请填写" />
                    <div style="width:14px"></div>
                </div>
                <div class="inputBox">
                    <div class="inputTitle">所在地区<span class="star">*</span></div>
                    <van-field v-model="receivingInfo.area" readonly @click="showArea" input-align="right"
                        placeholder="请选择" />
                    <van-icon name="arrow" size="14px" color="#999999" />
                </div>
                <div class="inputBox">
                    <div class="inputTitle">详细地址<span class="star">*</span></div><van-field
                        v-model="receivingInfo.detailedAddress" maxlength="50" input-align="right" placeholder="请填写" />
                    <div style="width:14px"></div>
                </div>
            </div>

            <div style="height:92px;width:100%;"></div>
            <div class="bottom">
                <button class="bottomButton" @click="toConfirm">确定</button>
            </div>

            <van-action-sheet v-model="areaShow" :close-on-click-overlay='closeOverlay'>
                <van-area :area-list="areaList" value="110101" visible-item-count="4" @confirm="confirmArea"
                    @cancel="cancelArea" />
            </van-action-sheet>

            <van-action-sheet v-model="patternShow" :close-on-click-overlay='closeOverlay'>
                <van-picker value-key="name" :columns="patternList" visible-item-count="4" show-toolbar title="请选择款式"
                    @cancel="cancelPattern" @confirm="confirmPattern" />
            </van-action-sheet>
        </div>
    </div>
</template>
<script>
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import { osspath } from "../../../util/config"
import OrderApi from "../api/index"
const orderApi = new OrderApi()

export default {
    data() {
        return {
            commodityName: '',  //商品名称
            goodId: '643c0b907ef9484af398ab77', //商品id
            versionList: [{ originalPrice: 0, image: '' }], //商品版本
            versionExpand: false,  //版本是否展开
            versionIndex: 0,  //版本序号
            closeOverlay: true, //点击遮罩层，不关闭上拉框
            additionalName: '',  //换购商品名
            additionalId: '',  //换购商品id
            additionalChecked: false,  //是否选择换购商品
            patternShow: false,  //换购商品款式选择上拉框展示
            patternList: [],  //换购商品款式列表
            pattern: { image: '' },  //换购商品=款式
            //收货信息
            receivingInfo: {},
            areaList: areaList,
            areaShow: false,  //上拉框展示
            areaArr: [], //区域数组
            osspath: osspath,
            loading: true,
            additionalShow: false,
        }
    },
    created() {
        //获取商品信息
        this.getData()
    },
    mounted() {

    },
    computed: {
    },
    methods: {
        // 获取商品信息
        getData() {
            // 获取商品信息
            orderApi.getCommodityDetail(this.goodId).then((res) => {
                let data = res.data
                this.commodityName = data.commodityName
                this.versionList = data.versionConfiguration
                this.additionalId = data.additionalId
                // 没有换购商品
                if (this.additionalId != undefined) {
                    this.additionalName = data.additionalName
                    this.additionalShow = true
                    // 获取换购商品信息
                    this.getExchangeGood()
                } else {
                    this.loading = false
                }

            })
        },
        // 获取换购商品信息
        async getExchangeGood() {
            let res = await orderApi.getExchangeGoodDetail(this.additionalId)
            let data = res.data
            this.additionalName = data.name
            this.patternList = data.versionConfiguration
            this.pattern = this.patternList[0]
            this.showData()
        },
        showData() {
            // 返回的时候渲染数据
            let data = JSON.parse(localStorage.getItem('orderInfo'))
            if (data != undefined && data != null) {
                this.additionalChecked = data.additionalChecked
                this.versionIndex = data.versionIndex
                if (this.additionalChecked) {
                    this.receivingInfo = data.receivingInfo
                    this.pattern = data.pattern
                    this.areaArr = data.areaArr
                }
            }
            this.loading = false
        },
        // 提交预约信息
        async createOrder() {
            let healthSurvey = JSON.parse(localStorage.getItem('healthSurvey'))
            let commodityInfo = {
                id: this.goodId,
                commodityName: this.commodityName,
                commodity: this.versionList[this.versionIndex]
            }
            let params = {}

            if (this.additionalChecked) {
                // 如果选择了加购商品
                let additionalInfo = {
                    additionalId: this.additionalId,
                    additionalName: this.additionalName,
                    additional: this.pattern
                }
                let area = {
                    province: this.areaArr[0].name,
                    provinceCode: this.areaArr[0].code,
                    city: this.areaArr[1].name,
                    cityCode: this.areaArr[1].code,
                    area: this.areaArr[2].name,
                    areaCode: this.areaArr[2].code,
                }
                params = {
                    commodityInfo,
                    additionalInfo,
                    healthQuestionnaire: {
                        consentForm: 1,
                        instructionsForUseice: 1,
                        ...healthSurvey,
                    },
                    receivingInfo: {
                        ...this.receivingInfo,
                        ...area
                    }
                }
            } else {
                params = {
                    commodityInfo,
                    healthQuestionnaire: {
                        consentForm: 1,
                        instructionsForUseice: 1,
                        ...healthSurvey,
                    }
                }
            }
            let res = await orderApi.createOrder(params)
            let id = res.data
            params.id = id
            localStorage.setItem("order", JSON.stringify(params))
            this.saveOrderInfo()
            this.$router.push("/confirmOrder")
        },
        // 去订单确认页
        toConfirm() {
            if (this.checkData()) {
                // 需要携带参数到确认页
                this.createOrder()
            }
        },
        // 商品选择版本
        chooseVersion(index) {
            this.versionIndex = index
        },
        // 保存填写的订单信息
        saveOrderInfo() {
            let data = {}
            if (this.additionalChecked) {
                data = {
                    additionalChecked: this.additionalChecked,  //是否选择换购商品
                    pattern: this.pattern,
                    receivingInfo: this.receivingInfo,
                    areaArr: this.areaArr,
                    versionIndex: this.versionIndex
                }
            } else {
                data = {
                    additionalChecked: this.additionalChecked,  //是否选择换购商品
                    pattern: this.pattern,
                    versionIndex: this.versionIndex
                }
            }
            localStorage.setItem('orderInfo', JSON.stringify(data))
        },
        // 去健康调查问卷页面
        healthSurvey() {
            this.saveOrderInfo()
            this.$router.push('/orderHealth')
            localStorage.setItem('isReadonly', 'false')
        },
        // 展示地区选择器
        showArea() {
            this.areaShow = true
        },
        // 选择地区
        confirmArea(value) {
            this.areaArr = value
            let arr = value.map((item) => {
                return item.name
            })
            this.receivingInfo.area = arr.join('-')
            this.areaShow = false
        },
        // 取消选择地区
        cancelArea() {
            this.areaShow = false
        },
        // 数据校验
        checkData() {
            // 校验商品选择版本
            if (this.versionIndex == null) {
                Toast("请选择版本")
                return false
            }

            // 校验是否填写问卷调查
            if (localStorage.getItem('isHealthAchieve') == null) {
                Toast("请填写健康调查问卷")
                return false
            }

            // 校验换购商品的信息
            let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
            if (this.additionalChecked) {
                // 如果勾选换购商品
                if (this.receivingInfo.userName == undefined || !this.receivingInfo.userName.trim()) {
                    Toast('请填写收货人');
                    return false
                } else if (this.receivingInfo.phone == undefined || !this.receivingInfo.phone.trim()) {
                    Toast('请填写联系电话');
                    return false
                } else if (this.receivingInfo.phone.trim() && !phonereg.test(this.receivingInfo.phone.trim())) {
                    Toast('联系电话格式不对');
                    return false
                } else if (this.receivingInfo.area == undefined || !this.receivingInfo.area) {
                    Toast('请选择所在地区');
                    return false
                } else if (this.receivingInfo.detailedAddress == undefined || !this.receivingInfo.detailedAddress.trim()) {
                    Toast('请填写详细地址');
                    return false
                }
            }
            return true
        },
        //换购商品-选择款式
        choosePattern() {
            this.patternShow = true
        },
        // 换购商品款式，弹框点击确定
        confirmPattern(value) {
            this.pattern = value
            this.patternShow = false
        },
        // 换购商品款式，弹框点击取消
        cancelPattern() {
            this.patternShow = false
        },
        expandVersion() {
            this.versionExpand = !this.versionExpand
        },
        // 选择换购商品，滑动页面到底部
        showLocation() {
            if (this.additionalChecked) {
                var t = document.body.clientHeight;
                window.scroll({ top: t, left: 0, behavior: 'smooth' });
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.goodTop {
    display: flex;
    align-items: center;
    width: 100%;
    height: 152px;
    background: #FFFFFF;
    padding: 16px;
    box-sizing: border-box;


    .goodPic {
        width: 90px;
        height: 120px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
        overflow: hidden;
        flex-shrink: 0
    }
}

.goodInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    color: #333333;

    .originalPrice {
        font-weight: 400;
        color: #CCCCCC;
        text-decoration: line-through;
        margin-top: 8px;
    }

    .price {
        font-size: 20px;

        color: #E02020;
        line-height: 28px;
    }
}

.goodVersion {
    margin-top: 12px;
    width: 100%;
    background-color: #fff;


    .versionBox {
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px 4px 16px;
    }

    .version {
        padding: 6px 10px;
        background: #F2F8FF;
        border-radius: 4px;

        font-size: 14px;

        color: #1477FD;
        margin: 0 20px 12px 0;
    }

    .versionActive {
        background-color: #1477FD;
        color: #FFFFFF;
    }

}

.scroll {
    transform: rotate(90deg);
    transition: transform .3s;
}

.noscroll {
    transform: rotate(0);
    transition: transform .3s;
}

.wrapperTitle {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    display: flex;
    align-items: center;

    color: #333333;

    .line {
        width: 2px;
        height: 10px;
        background: #1477FD;
        border-radius: 1px;
        margin-right: 8px;
    }
}

.goodImg {
    margin-top: 12px;
    // height: 232px;
    background: #FFFFFF;
    padding: 20px 16px;
}

.health {
    height: 64px;
    background: #FFFFFF;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    color: #333333;

    .healthButton {
        width: 96px;
        height: 40px;
        background: #1477FD;
        border-radius: 8px;

        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
    }
}

.additional {
    height: 167px;
    background: #FFFFFF;
    margin-top: 12px;
    padding: 16px;
    box-sizing: border-box;

}

.additionalInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 17px;
    padding-right: 2px;
    border-bottom: 1px solid #E6E6E6;

    .additionalPic {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
    }

    .additionalContent {
        margin-left: 16px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
        word-wrap: break-word;
        overflow: hidden;
    }
}

.choosePattern {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 11px;
}



.mailLocation {
    margin-top: 12px;
    padding: 16px 16px 0 16px;
    background-color: #fff;
}

.inputBox {
    height: 52px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E6E6;

    .inputTitle {
        font-size: 14px;

        color: #333333;
        width: 100px;
        line-height: 22px;
    }
}

.star {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;

    color: #E02020;
    margin-left: 8px;
}

.van-cell {
    height: 52px;
    flex: 1;
    padding-left: 0 !important;
    padding-right: 0px !important;
    font-size: 14px;

    color: #333333;

    ::v-deep .van-field__body {
        height: 32px;
    }
}

::v-deep .van-picker__confirm {
    color: #1477FD;
}

// 折叠面板样式
.van-collapse-item {
    ::v-deep .van-cell {
        padding: 16px !important;
        margin-top: 12px;
    }

    ::v-deep .van-cell__title {
        font-size: 16px !important;
        display: flex;
        align-items: center;
    }

    ::v-deep .van-icon {
        font-size: 14px;
    }

    ::v-deep .van-cell__title::before {
        content: '';
        width: 2px;
        height: 10px;
        background: #1477FD;
        border-radius: 1px;
        margin-right: 8px;
        display: inline-block;
    }

    ::v-deep .van-cell::after {
        border: none !important;
    }

    ::v-deep .van-collapse-item__title .van-cell__right-icon::before {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    ::v-deep .van-collapse-item__title--expanded .van-cell__right-icon::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    ::v-deep .van-collapse-item__content {
        padding: 0px 16px 4px 16px;
    }
}

.van-skeleton {
    padding: 0 !important;
}

// 不能滚动
.van-skeleton__content {
    height: 100vh;
    overflow: hidden;
}

.van-skeleton__content .van-skeleton__row:nth-child(1) {
    width: 100% !important;
    height: 152px;
    background: #FFFFFF;
}

.van-skeleton__content .van-skeleton__row:nth-child(2) {
    width: 100% !important;
    height: 102px !important;
    margin-top: 12px;
    background-color: #fff;
}

.van-skeleton__content .van-skeleton__row:nth-child(3) {
    width: 100% !important;
    height: 232px !important;
    margin-top: 12px;
    background-color: #fff;
}

.van-skeleton__content .van-skeleton__row:nth-child(4) {
    width: 100% !important;
    height: 64px !important;
    margin-top: 12px;
    background-color: #fff;
}

.van-skeleton__content .van-skeleton__row:nth-child(5) {
    width: 100% !important;
    height: 167px !important;
    margin-top: 12px;
    background-color: #fff;
}
</style>
