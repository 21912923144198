// 配置首页接口
import request from "../../../util/request"

export default class OrderApi {

    // 获取商品详情
    async getCommodityDetail(id) {
        const res = await request.get('/apis/commodity/query/detail?id=' + id)
        return res.data;
    }

    // 获取加购商品详情
    async getExchangeGoodDetail(id) {
        const res = await request.get('/apis/additional/detail?id=' + id)
        return res.data;
    }

    // 提交预约信息
    async createOrder(params) {
        const res = await request.post('/apis/order/create', params)
        return res.data;
    }

    // 订单列表
    async orderList(params) {
        const res = await request.post('/apis/order/list', params)
        return res.data;
    }

    // 订单详情
    async orderDetail(id) {
        const res = await request.get('/apis/order/detail?id=' + id)
        return res.data;
    }

    // 订单超时，取消订单
    async endOrder(id) {
        const res = await request.post('/apis/order/end?id=' + id)
        return res.data;
    }

    // 报告发送短信验证码
    async sendCode(id) {
        const res = await request.get('/apis/order/sendVerify?id=' + id)
        return res.data;
    }


    // 查看报告
    async getReport(id, code) {
        const res = await request.post('/apis/order/getRepor', { id: id, code: code })
        return res.data;
    }

    // 调用微信支付接口
    async pay(params) {
        const res = await request.post('/apis/order/pay', params)
        return res.data;
    }

    // 支付回调
    async payCallback(id) {
        const res = await request.get('/apis/order/success?i=' + id)
        return res.data;
    }
}